<template>
  <div>
    <v-app-bar
      dense
      dark
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-toolbar-title @click="$router.push({name:'Home'})"><h1 style="color: #6441a5; font-size: 30px;">Bity Alerts Dashboard</h1></v-toolbar-title>

      <v-spacer></v-spacer>

      <div v-if="user.loggedIn">
      <v-tooltip bottom v-for="item in items" :key="item.to">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            color="secondary"
            :to="item.to"
          >
            <v-icon>{{item.icon}}</v-icon>
          </v-btn>
        </template>
        <span>{{item.text}}</span>
      </v-tooltip>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      items: [
        {
          icon: 'mdi-circle-multiple',
          text: 'My Alerts',
          to: 'alerts'
        },
        {
          icon: 'mdi-text-box',
          text: 'My Page',
          to: 'page'
        },
        {
          icon: 'mdi-account',
          text: 'My Account',
          to: 'dashboard'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
  }
};
</script>