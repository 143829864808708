<template>
    <div>
        <v-file-input
        v-model="image"

        label="Image"
        required
        @change="onFileChange"
        ></v-file-input>
        <img
        v-if="imagePreviewURL"
        :src="imagePreviewURL"
        alt=""
        style="max-width: 100%;width: 250px; object-fit: cover"
        />
        <v-btn
            outlined
            text
            color="secondary"
            @click="saveFile"
        >
            Save Image
        </v-btn>
        <div v-if="saved" style="text-align: center;">Image Saved!</div>
        <div v-if="error" style="text-align: center;">{{error}}</div>
    </div>
</template>
<script>
import firebase from 'firebase'
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
    props: {
      alert_id: {type: String, required: false, default: null}
    },
    data() {
        return {
            imagePreviewURL: null,
            image: null,
            saved: false,
            error: null
        }
    },
    methods: {
        onFileChange(payload) {
            const file = payload
            if (file) {
                this.imagePreviewURL = URL.createObjectURL(file);
                URL.revokeObjectURL(file)
            } else {
                this.imagePreviewURL =  null
            }
        },
        makeid(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        saveFile() {
            // Create a root reference
            let ref = this.alert_id || uuidv4();
            var storageRef = firebase.storage().ref().child(ref);

            storageRef.put(this.image).then((data) => {
                console.log('Uploaded a blob or file!');
                this.$emit('uploaded', {
                    refid: ref,
                    ...data
                })
                this.saved = true
            }).catch(e => {
                this.error = e.message
            })
        },
    },
    computed: {
        ...mapGetters({
            user: "user"
        }),
    }
}
</script>