<template>
  <v-card
    class="mx-auto"
    style="margin-top: 20px"
    max-width="600"
    :loading="loading"
  >
    <v-img height="200px" :src="imagePreviewURL">
      <v-card-title class="white--text">
        <v-btn text @click="$emit('back')">
          <v-icon color="white">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <p class="mx-auto pt-4">New Alert</p>
      </v-card-title>
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
          :style="`background: ${randoGrad}`"
        >
        </v-row>
      </template>
    </v-img>
    <v-file-input
      v-model="StageData.image"
      label="Click here to select an Image or Gif"
      required
      show-size
      @change="onFileChange"
      truncate-length="15"
      style="margin: 10px"
    ></v-file-input>

    <v-text-field
      name="name"
      label="Title"
      type="text"
      v-model="AlertData.name"
      style="margin: 10px"
    ></v-text-field>

    <v-text-field
      name="description"
      label="Description"
      type="text"
      v-model="AlertData.description"
      style="margin: 10px"
    ></v-text-field>

    <v-text-field
      name="bits"
      label="Bits"
      type="Number"
      v-model="AlertData.bits"
      style="margin: 10px"
    ></v-text-field>
    <div v-if="saved" style="text-align: center">Alert Saved!</div>
    <v-card-actions>
      <v-btn
        outlined
        :disabled="loading || saved"
        @click="createAlert"
        text
        color="accent"
      >
        Create Alert
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="errorDialog" width="500">
      <v-card>
        <v-card-title> Upload Error </v-card-title>

        <v-card-text>
          {{ ImageError }}
          {{ errors.length > 0 ? errors : "" }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="errorDialog = false">
            Acknowledge
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone"

export default {
  data() {
    return {
      currentTab: null,
      imagePreviewURL: null,
      tabs: [
        {
          id: "create",
          name: "Select Image/Gif",
          icon: "mdi-image",
          component: "imager",
        },
        {
          id: "finalize",
          name: "Review/Save Alert",
          icon: "mdi-check-decagram",
          component: "imager",
        },
      ],
      StageData: {
        image: null,
      },
      AlertData: {
        streamer: null,
        src: null,
        description: null,
        name: null,
        bits: null,
        uid: null,
      },
      loading: false,
      saved: false,
      errors: [],
      ImageError: null,
      errorDialog: false,
    };
  },
  methods: {
    onFileChange(payload) {
      const file = payload;
      if (file) {
        this.imagePreviewURL = URL.createObjectURL(file);
        URL.revokeObjectURL(file);
      } else {
        this.imagePreviewURL = null;
      }
    },
    async createAlert() {
      this.loading = true;
      this.ImageError = null;
      this.errors = [];
      // Check if file exists
      if (!this.StageData.image) {
        this.ImageError = "No Image or GIF was detected";
        this.errorDialog = true;
        this.loading = false;
        return;
      }

      if (!this.user.data.displayName) {
        this.errors.push("User Data Not Found Yet");
        this.errorDialog = true;
        this.loading = false;
        return;
      }

      if (!this.AlertData.bits || !this.AlertData.name) {
        this.errors.push("Missing Data");
        this.errorDialog = true;
        this.loading = false;
        return;
      }

      // generate random uuid for file
      let ref = uuidv4();
      var storageRef = firebase.storage().ref().child(ref);
      await storageRef
        .put(this.StageData.image)
        .then(() => {
          this.AlertData.src = ref;
        })
        .catch((e) => {
          console.error(e);
          this.errorDialog = true;
          this.ImageError = "There was an error uploading the image";
          this.loading = false;
        });

      if (this.ImageError) {
        this.loading = false;
        return;
      }

      // Ensure data is formatted
      let that = this;
      const streamers = this.$firebase;
      this.AlertData.streamer = this.user.data.displayName.toLowerCase();
      this.AlertData.uid = this.user.data.uid;
      this.AlertData.bits = parseFloat(this.AlertData.bits || 0);

      streamers
        .collection("alerts")
        .add({
          ...that.AlertData,
          ts: moment().tz("America/Los_Angeles").toISOString()
        })
        .then(() => {
          that.saved = true;
          that.loading = false;
        })
        .catch((e) => {
          console.error(e);
          that.error.push(e.message);
          that.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    randoGrad() {
      var hexValues = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
      ];

      function populate(a) {
        for (var i = 0; i < 6; i++) {
          var x = Math.round(Math.random() * 14);
          var y = hexValues[x];
          a += y;
        }
        return a;
      }

      var newColor1 = populate("#");
      var newColor2 = populate("#");
      var angle = Math.round(Math.random() * 360);

      return (
        "linear-gradient(" +
        angle +
        "deg, " +
        newColor1 +
        ", " +
        newColor2 +
        ")"
      );
    },
  },
};
</script>
