<template>
  <div>
      404 | Oh no! It appears you stumbled in the dark and ran into an unknown page!
  </div>
</template>

<script>

export default {
  metaInfo: {
    title: 'Not Found'
  },
  name: 'NotFound',
}
</script>
