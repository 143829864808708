
import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'

// Views
import Alerts from '../views/Alerts'
import Account from '../views/Account'
import Login from '../views/Login'
import SignUp from '../views/SignUp'
import fourohfour from '../views/FourOhFour'
import Page from '../views/Page'

// Components
import Image from '../components/Image'


Vue.use(Router)

// Override catch
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/404',
      name: 'NotFound',
      component: fourohfour
    },
    {
      name: 'Home',
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp
    },
    {
      path: '/image',
      name: 'Image',
      component: Image,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/alerts',
      name: 'Alerts',
      component: Alerts,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Account,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/page',
      name: 'page',
      component: Page,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '*',
      redirect: '404'
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  let currentUser = await firebase.getCurrentUser()
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isLoggedin = Boolean(currentUser && currentUser.email)
  if (requiresAuth && !isLoggedin) next('login')
  else if ((to.name == 'Login' || to.name == 'SignUp') && isLoggedin) next('dashboard')
  else next()
})

export default router