import firebase from 'firebase/app'
import 'firebase/firestore'
export default {
    install (Vue, options = {}) {
        if (!options.config) {
            console.error('[Firebase Plugin] Missing config option')
        }
        if (!options.store) {
            console.error('[Firebase Plugin] Missing store option')
        }

        firebase.initializeApp(options.config);
        firebase.analytics();

        firebase.getCurrentUser = () => {
            return new Promise((resolve, reject) => {
                const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                    unsubscribe();
                    resolve(user);
                }, reject);
            })
        };

        firebase.makeAwait = (data) => {
            return new Promise((resolve, reject) => {
                data.then(res => {
                    console.log(res)
                    resolve(res);
                }).catch (e => {
                    reject(e)
                })
            })
        };

        let db = firebase.firestore()
        // Setup instance access
        Vue.prototype.$firebase = db

        firebase.auth().onAuthStateChanged(user => {
            let sdata = {}
            if (user) {
                db.collection('streamers').doc(user.uid).get().then((querySnapshot) => {
                    sdata = querySnapshot.data()
                    options.store.dispatch("fetchUser", {
                        ...user,
                        ...sdata
                    });
                }).catch(e => {
                    console.error(e)
                    options.store.dispatch("fetchUser", {
                        ...user,
                        ...sdata
                    });
                })
            }
            options.store.dispatch("fetchUser", {
                ...user,
                ...sdata
            });
        });

        console.log('[Firebase Plugin] Installed!')
    }
}