<template>
  <v-card class="mx-auto" max-width="344">
    <div style="height: 200px !important; overflow: hidden">
      <v-img
        v-if="data.src && play"
        height="200px"
        :id="refid"
        @load="getThumbnail"
        @click="play = false"
        :src="`https://firebasestorage.googleapis.com/v0/b/bityalerts.appspot.com/o/${data.src}?alt=media`"
      >
        <v-icon
          medium
          dark
          style="position: absolute; right: 10px; top: 10px; cursor: pointer"
          @click="editing = !editing"
        >
          mdi-cog
        </v-icon>
        <v-icon
          medium
          dark
          style="position: absolute; right: 50px; top: 10px; cursor: pointer"
          @click="imageupload = !imageupload"
        >
          mdi-image
        </v-icon>
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
            :style="`background: ${randoGrad}`"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-img
        v-show="data.src && !play"
        height="200px"
        :id="refid"
        @load="getThumbnail"
        class="freezeframe"
        src=""
      >
        <template v-slot:default>
          <v-icon
            medium
            dark
            style="position: absolute; right: 10px; top: 10px; cursor: pointer"
            @click="editing = !editing"
          >
            mdi-cog
          </v-icon>
          <v-icon
            medium
            dark
            style="position: absolute; right: 50px; top: 10px; cursor: pointer"
            @click="imageupload = !imageupload"
          >
            mdi-image
          </v-icon>
          <v-row
            @click="play = true"
            class="fill-height ma-0"
            align="center"
            justify="center"
            style="color: white; font-weight: bold"
            :style="`background: ${randoGrad};  cursor: pointer;`"
          >
            Click to view Image/GIF
          </v-row>
        </template>
      </v-img>
      <div
        v-show="!data.src"
        :style="`height: 200px; width: 100%; background: ${randoGrad}`"
      >
        <v-icon
          medium
          dark
          style="position: absolute; right: 10px; top: 10px; cursor: pointer"
          @click="editing = !editing"
        >
          mdi-cog
        </v-icon>
        <v-icon
          medium
          dark
          style="position: absolute; right: 50px; top: 10px; cursor: pointer"
          @click="imageupload = !imageupload"
        >
          mdi-image
        </v-icon>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
          style="color: #ffffff; font-weight: bold"
        >
          No Media Found
        </v-row>
      </div>
    </div>
    <imager
      v-if="imageupload"
      :alert_id="data.src"
    ></imager>

    <div v-if="!editing">
      <v-card-title>
        {{ data.name }}
      </v-card-title>

      <v-card-subtitle style="font-weight: bold" color="orange lighten-2">
        {{ data.bits }} Bits
      </v-card-subtitle>

      <v-card-text>
        {{ data.description }}
      </v-card-text>
    </div>
    <div v-if="editing">
      <v-text-field
        name="name"
        label="Title"
        type="text"
        v-model="data.name"
        style="margin: 10px"
      ></v-text-field>

      <v-text-field
        name="bits"
        label="Bits"
        type="Number"
        v-model="data.bits"
        style="margin: 10px"
      ></v-text-field>

      <v-text-field
        name="description"
        label="Description"
        type="text"
        v-model="data.description"
        style="margin: 10px"
      ></v-text-field>

      <v-card-actions>
        <v-btn outlined text color="secondary" @click="saveAlert"> save </v-btn>
        <v-btn outlined text color="accent" @click="deleteAlert"> delete </v-btn>
      </v-card-actions>
      <div v-if="saved" style="text-align: center">Alert Updated!</div>
      <div v-if="error" style="text-align: center">{{ error }}</div>
    </div>
  </v-card>
</template>
<script>
import imager from "./Image";
export default {
  props: {
    src: { type: String, required: false },
    description: { required: false, default: null },
    name: { type: String, required: true },
    bits: { type: Number, required: true },
    id: { type: String, required: true },
  },
  data: () => ({
    logo: null,
    play: false,
    favorite: false,
    editing: false,
    data: {},
    saved: false,
    error: null,
    imageupload: false,
  }),
  components: {
    imager,
  },
  watch: {},
  mounted() {
    this.data = {
      src: this.src,
      description: this.description,
      name: this.name,
      bits: this.bits,
    };
  },
  methods: {
    deleteAlert() {
      const streamers = this.$firebase;
      streamers
      .collection("alerts")
      .doc(this.id)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    saveAlert() {
      const streamers = this.$firebase;
      streamers
        .collection("alerts")
        .doc(this.id)
        .update(this.data)
        .then(() => {
          this.saved = true;
        })
        .catch((e) => {
          this.error = e.message;
        });
    },
    async getThumbnail(data) {
      console.log(data);
    },
    toggleGif(id) {
      var image = document.getElementById(id);
      if (image.classList && image) {
        if (this.playstate == "pause") {
          image.classList.add("pause");
          this.playstate = "play";
        } else {
          image.classList.remove("pause");
          this.playstate = "pause";
        }
      }
    },
  },
  computed: {
    refid() {
      return "tile-" + this.src;
    },
    randoGrad() {
      var hexValues = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
      ];

      function populate(a) {
        for (var i = 0; i < 6; i++) {
          var x = Math.round(Math.random() * 14);
          var y = hexValues[x];
          a += y;
        }
        return a;
      }

      var newColor1 = populate("#");
      var newColor2 = populate("#");
      var angle = Math.round(Math.random() * 360);

      return (
        "linear-gradient(" +
        angle +
        "deg, " +
        newColor1 +
        ", " +
        newColor2 +
        ")"
      );
    },
  },
};
</script>