<template>
   <v-app id="inspire">
      <v-content>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="accent">
                        <v-toolbar-title>Create an account</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                          <v-text-field
                              id="username"
                              prepend-icon="mdi-account"
                              name="username"
                              label="Username [Twitch Name]"
                              type="text"
                              v-model="form.name"
                              :error="error"
                           ></v-text-field>
                           <v-text-field
                              prepend-icon="mdi-email"
                              name="login"
                              label="Email"
                              type="text"
                              v-model="form.email"
                              :error="error"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              prepend-icon="mdi-lock"
                              name="password"
                              label="Password"
                              type="password"
                              v-model="form.password"
                              :error="error"
                           ></v-text-field>
                        </v-form>
                        <div v-if="error">{{error}}</div>
                     </v-card-text>
                     <v-card-actions>
                        <router-link to="login">Already have an account? Sign in!
                        </router-link>
                        <v-spacer></v-spacer>
                        <v-btn @click="submit" color="accent">Register</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-content>
   </v-app>
</template>

<script>
import firebase from "firebase";
export default {
   metaInfo: {
    title: 'Sign Up'
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: ""
      },
      error: null
    };
  },
  methods: {
    validateString(str) {
      return str && str !== ''
    },
    async submit() {
      let that = this
      const streamers = this.$firebase;
      let username = that.form.name.toLowerCase()
      let doc = streamers.collection("streamers")

      // Ensure steramer doesnt already exist
      var query = await doc.where("streamer", "==", username).get()

      if (!query.empty) {
         this.error = 'streamer already exists'
         return   
      }

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then(data => {
          data.user
            .updateProfile({
              displayName: username
            })
            .then(() => {
               // Setup Alerts Config
               streamers.collection("streamers").doc(data.user.uid).set({
                  streamer: username,
                  profileSrc: null,
                  page: {
                     displayName: that.form.name,
                     icon: {
                        src: null
                     }
                  }
               })
               .then(() => {
                  that.$router.replace({ name: "Login" });
               })
               .catch((error) => {
                  console.error("Error creating account: ", error);
               });
            });
        })
        .catch(err => {
          that.error = err.message;
        });
    }
  }
};
</script>