<template>
  <v-app class="my-app" :style="{background: $vuetify.theme.themes[theme].background }">
    <navbar></navbar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import navbar from './components/Navbar.vue'
export default {
  name: 'App',
  metaInfo: {
    title: 'App',
    titleTemplate: '%s - BityAlerts'
  },
  components: {
    navbar
  },
  mounted () {
    this.$vuetify.theme.dark = false
  },
  data: () => ({
    //
  }),
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  }
};
</script>
<style scoped>
a {
  color: #6441a5 !important;
}
</style>
