import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import firebase from './plugins/firebase.js'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(firebase, {
  store: store,
  config: {
    apiKey: "AIzaSyCbCYGe7e6XOz_C_Bjn84yrCxrqVkrhFVk",
    authDomain: "bityalerts.firebaseapp.com",
    projectId: "bityalerts",
    storageBucket: "bityalerts.appspot.com",
    messagingSenderId: "368223155831",
    appId: "1:368223155831:web:090fcbad6c2dcd9c107872",
    measurementId: "G-6MRPHZML1P"
  }
})
Vue.use(VueMeta)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
