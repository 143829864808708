import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                text: '#d6d6d6',
                color: '#ffffff',
                primary: '#efefef',
                secondary: '#3add1f',
                accent: '#E27396',
                background: '#efefef',
                main: '#6441a5'
            },
            dark: {
                text: '#ffffff',
                primary: '#d6d6d6',
                secondary: '#3add1f',
                accent: '#E27396',
                background: '#6441A5',
                navigationbar: '#363636',
                main: '#6441a5'
            },
        },
    },
});
