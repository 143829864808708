<template>
  <v-card
    class="mx-auto"
    max-width="344"
    outlined
    style="margin-top: 60px;"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">
          Account
        </div>
        <v-list-item-title class="headline mb-1">
          {{streamer.page && streamer.page.displayName}}
        </v-list-item-title>
        <!-- <v-list-item-subtitle>{{user.data.email}}</v-list-item-subtitle> -->
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"
        color="grey"
        :style="`background: ${randoGrad}`"
      >
        <img v-if="streamer.profileSrc" :src="streamer.profileSrc" class="rounded-circle" :alt="streamer.profileSrc" />
        <span v-else class="white--text headline">{{gtitle}}</span>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        text
        @click="signOut"
      >
        Sign Out
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
import { mapGetters } from "vuex";
import firebase from "firebase";

export default {
  metaInfo: {
    title: 'Dashboard'
  },
  data () {
    return {
      streamer: {}
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    }),
    gtitle() {
      return this.streamer && this.streamer.streamer && this.streamer.streamer.charAt(0).toUpperCase() || ''
    },
    randoGrad () {
      var hexValues = ["0","1","2","3","4","5","6","7","8","9","a","b","c","d","e"];

      function populate(a) {
        for ( var i = 0; i < 6; i++ ) {
          var x = Math.round( Math.random() * 14 );
          var y = hexValues[x];
          a += y;
        }
        return a;
      }

      var newColor1 = populate('#');
      var newColor2 = populate('#');
      var angle = Math.round( Math.random() * 360 );

      return "linear-gradient(" + angle + "deg, " + newColor1 + ", " + newColor2 + ")";
    }
  },
  mounted () {
    this.getStreamer(this.user.data)
  },
  methods: {
    getStreamer: function (data) {
      var that = this
      const streamers = this.$firebase;
      streamers.collection('streamers').doc(data.uid).get().then((querySnapshot) => {
        that.streamer = querySnapshot.data()
      });
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Home"
          });
        });
    }
  }
};
</script>