<template>
  <div>
    <h1 class="pt-10 ml-10">Streamer Page</h1>
    <hr class="ml-10 mr-10"/>
    <v-card class="mt-10 mx-10 px-5 pb-5">
      <h3 class="pt-5">Page Image</h3>
      <v-img height="200px" width="200px" :src="compsrc"> </v-img>
      <imager :alert_id="streamer.page.icon && streamer.page.icon.src || null" @uploaded="handleUpload"></imager>
    </v-card>
    <v-card class="mt-10 mx-10 px-5 pb-5">
      <h3 class="pt-5">Page Description</h3>
      <v-text-field
        name="description"
        label="Page Description"
        type="text"
        v-model="streamer.page.text"
        style="margin: 10px"
      ></v-text-field>

      <v-btn outlined text color="secondary" @click="savePage">
        Save Page
      </v-btn>
    </v-card>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import imager from "../components/Image";
export default {
  metaInfo: {
    title: "Streamer Page",
  },
  data() {
    return {
      streamer: {
        page: {
          text: null,
        },
      },
    };
  },
  components: {
    imager,
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
    compsrc() {
      if (!this.streamer.page.icon) return "";
      return `https://firebasestorage.googleapis.com/v0/b/bityalerts.appspot.com/o/${this.streamer.page.icon.src}?alt=media`;
    },
  },
  mounted() {
    this.getStreamerPage();
  },
  methods: {
    handleUpload(resp) {
      this.streamer.page.icon.src = resp.refid;
      console.log(this.page);
      this.savePage();
    },
    savePage() {
      const streamers = this.$firebase;
      streamers
        .collection("streamers")
        .doc(this.user.data.uid)
        .update(this.streamer)
        .then(() => {
          this.saved = true;
        })
        .catch((e) => {
          this.error = e.message;
        });
    },
    getStreamerPage: function () {
      var that = this;
      const streamers = this.$firebase;
      streamers
        .collection("streamers")
        .doc(this.user.data.uid)
        .get()
        .then((querySnapshot) => {
          that.streamer = querySnapshot.data();
        });
    },
  },
};
</script>