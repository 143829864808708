<template>
  <div v-if="user.data && (user.data.verified || user.data.admin)">
    <v-card flat v-show="!add">
      <v-card-actions>
        <v-btn outlined text color="accent" @click="add = !add">
          <v-icon class="pr-2">mdi-plus-circle</v-icon>
          Add Alert
        </v-btn>
      </v-card-actions>
    </v-card>
    <newtile @back="add = false" v-if="add"></newtile>
    <div class="container-grid" v-else>
      <tiles
        class="tiles-grid"
        v-for="(alert, i) in alerts"
        :key="alert.description + i"
        :src="alert.src"
        :bits="alert.bits"
        :name="alert.name"
        :description="alert.description"
        :id="alert.id"
      >
      </tiles>
    </div>
  </div>
  <h3 v-else-if="user.data && (!user.data.verified)" style="margin: 10px">
    <br />Coming soon to verified users!
    <small>This is currently in an invite only Alpha phase.</small>
  </h3>
</template>


<script>
import { mapGetters } from "vuex";
// import firebase from "firebase";
import tiles from "../components/Tile";
import newtile from "../components/CreateAlert";

export default {
  metaInfo: {
    title: "Alerts",
  },
  data() {
    return {
      alerts: [],
      add: false,
    };
  },
  components: {
    tiles,
    newtile,
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  mounted() {
    this.getAlerts();
  },
  watch: {
    add: function () {
      this.getAlerts();
    }
  },
  methods: {
    getAlerts: function () {
      this.alerts = [];
      var that = this;
      const streamers = this.$firebase;
      streamers
        .collection("alerts")
        .where("streamer", "==", this.user.data.displayName.toLowerCase())
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((element) => {
            that.alerts.push({
              id: element.id,
              ...element.data(),
            });
            console.log(that.alerts)
          });
        });
    },
  },
};
</script>
<style scoped>
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 10px;
  margin: 10px;
}

.tiles-grid {
  width: 300px;
}
</style>